import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import Header from '../components/header/header'
import PageContainer from '../components/pages/page-container'
import Footer from '../components/layout/footer'
import CookieBox from '../components/cookie/cookie-box'
import '../components/pages/press.scss'

const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>DARC-EXP | 404</title>
        <body className="404 presspage" />
      </Helmet>
      <div className="404">
        <Header darkTheme />
        <CookieBox />
        <PageContainer>
          <h3>404 not found</h3>
          <Link to="/">
            <button>Go back</button>
          </Link>
        </PageContainer>
        <Footer />
      </div>
    </>
  )
}

export default NotFoundPage
